import { TimelineMax, Power3, Back } from 'gsap'

export default () => {
    // State

    // Methods
    const methods = {
        handleClose() {
            const id = event.srcElement.dataset['id'];
            const modal = document.querySelector(`.block-modal[data-id="${id}"]`);
            if (modal) {
                const timeline = new TimelineMax()
                    .fromTo(modal, 1, {css: {display: 'block', opacity: 1}}, {css: {display: 'none', opacity: 0}, ease: Power3.easeInOut }, 0)
                    .fromTo(modal.querySelector('.modal'), 1, {css: {display: 'block', scale: 1}}, {css: {display: 'none', scale: .9}, ease: Power3.easeInOut }, 0)
            }
        },
        handleOpen(event) {
            const id = event.srcElement.dataset['id'];
            const modal = document.querySelector(`.block-modal[data-id="${id}"]`);
            if (modal) {
                const timeline = new TimelineMax()
                    .fromTo(modal, .2, {css: {display: 'none', opacity: 0}}, {css: {display: 'block', opacity: 1}, ease: Power3.easeInOut }, 0)
                    .fromTo(modal.querySelector('.modal'), 1, {css: {display: 'none', scale: 1.05, opacity: 0}}, {css: {display: 'block', scale: 1, opacity: 1}, ease: Power3.easeInOut }, 0)
            }
        }
    };

    // Listeners
    [...document.querySelectorAll('.open-modal')].forEach((item) => {
        item.addEventListener('click', methods.handleOpen, false);
    });
    [...document.querySelectorAll('.block-modal .close')].forEach((item) => {
        item.addEventListener('click', methods.handleClose, false);
    });
};
