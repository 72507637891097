import { TimelineMax, Power3, Back } from 'gsap'

export default () => {
    // State
    const burgerMenu = document.querySelector('.mobile-menu');
    const contentWrapper = document.querySelector('.wrapper');
    const overlay = document.querySelector('.wrapper .overlay');

    // Methods
    const methods = {
        handleOpen() {
            new TimelineMax()
                .fromTo(contentWrapper, 1, {css: {left: '-224px'}}, {css: {left: 0}, ease: Power3.easeInOut }, 0)
                .to(contentWrapper, 0, {css: {overflow: 'hidden', height: '100vh'}, ease: Power3.easeInOut }, 0)
                .fromTo(overlay, 1, {css: {display: 'none', opacity: 0}}, {css: {display: 'block', opacity: 1}, ease: Power3.easeInOut }, 0);
        },
        handleClose() {
            new TimelineMax()
                .fromTo(contentWrapper, 1, {css: {left: '0'}}, {css: {left: '-224px'}, ease: Power3.easeInOut }, 0)
                .to(contentWrapper, 0, {css: {overflow: '', height: ''}, ease: Power3.easeInOut }, 0)
                .fromTo(overlay, 1, {css: {display: 'block', opacity: 1}}, {css: {display: 'none', opacity: 0}, ease: Power3.easeInOut }, 0);
        }
    };

    // Listeners
    document.querySelector('.burger')
        .addEventListener('click', methods.handleOpen, false);
    document.querySelector('.wrapper .overlay')
        .addEventListener('click', methods.handleClose, false);
};
