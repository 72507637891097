import age from 'get-age';
import Cookies from 'js-cookie';
import { TimelineMax, Power3, Back } from 'gsap';
import dombo from 'dombo';

export default () => {    
    // State
    const formElement = document.querySelector('form[name="age-gate"]');
    const ageGateElement = document.querySelector('.age-gate');
    const errorMessageElement = document.querySelector('.error-message');
    let ageGateOpen = false;

    // Methods
    const methods = {
        init() {
            Cookies.set('ageGateOpen', Cookies.get('ageGateOpen') || false);
            ageGateOpen = Cookies.get('ageGateOpen');
        },
        handleSuccess() {
            Cookies.set('ageGateOpen', true);
            window.scrollTo(0,0);
            dombo('html').removeClass('overflow-hidden');
            new TimelineMax()
                .fromTo(ageGateElement, 1, {css: {opacity: 1}}, {css: {opacity: 0}, ease: Power3.easeInOut }, 0)
                .fromTo(ageGateElement, 0, {css: {display: 'block'}}, {css: {display: 'none'}, ease: Power3.easeInOut }, 1);
        },
        handleFailure() {
            window.location = formElement.querySelector('[name="failure_redirection"]').value;
        }
    }
    methods.init();

    // Events
    if (!!formElement) {
        formElement
        .querySelector('input[name="yes"]')
        .addEventListener('click', (e) => {
            methods.handleSuccess();
        }, false);

        formElement
        .querySelector('input[name="no"]')
        .addEventListener('click', (e) => {
            methods.handleFailure();
        }, false);
    }
}