import $ from 'jquery';
import scrollToWithAnimation from 'scrollto-with-animation';

export default () => {
    // Methods
    const methods = {
        init() {
            const hash = window.location.hash;
            if (hash !== '') {
                this.handleScroll(hash.substring(1));
            }
        },
        handleScroll(id) {
            if ($('[data-scrollto-id="' + id + '"]').length > 0) {
                const scrollTo = $('[data-scrollto-id="' + id + '"]').offset().top;
                scrollToWithAnimation(
                    document.querySelector('.wrapper'),
                    'scrollTop',
                    scrollTo,
                    1000,
                    'easeInOutCirc'
                );
            }
        }
    };
    methods.init();

    // Listeners
    $('a').on('click', (event) => {
        if (event.currentTarget.getAttribute('href').charAt(0) === '#') {
            event.preventDefault();
            const id = event.currentTarget.getAttribute('href').substr(1);
            window.location.hash = id;
            methods.handleScroll(id);
        }
    });
};
