'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rAF = undefined;

var _easings = require('./easings');

var _easings2 = _interopRequireDefault(_easings);

var _animationFrame = require('animation-frame');

var _animationFrame2 = _interopRequireDefault(_animationFrame);

var _package = require('./../package.json');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var rAF = new _animationFrame2.default();

var DEBUG = process.env.NODE_ENV || true;
var DEFAULT_ANIMATION = 'easeInQuad';
var LIB_NAME = _package.name + '@' + _package.version;
var TRANSITION_NOT_FOUND = LIB_NAME + ': Transition not found - ' + _package.repository.url;
var ANIMATION_NOT_VALID = LIB_NAME + ': callback transition don\'t look like a valid equation - ' + _package.repository.url;
var TRANSITION_NOT_VALID = LIB_NAME + ': Transition isn\'t string or Function - ' + _package.repository.url;

var ANIMATION_CANCEL = 'animation-cancel';
var ANIMATION_END = 'animation-end';

var _document = typeof document !== 'undefined' ? document : {};
var _window = typeof window !== 'undefined' ? window : {};

var findAnimation = function findAnimation() {
  var transition = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_ANIMATION;

  var animation = _easings2.default[transition];
  if (animation === undefined && DEBUG) {
    throw new Error(TRANSITION_NOT_FOUND);
  }
  return animation;
};

var defineAnimation = function defineAnimation(transition) {
  if (transition.length !== 4 && DEBUG) {
    throw new Error(ANIMATION_NOT_VALID);
  }
  return transition;
};

var scrollToWithAnimation = function scrollToWithAnimation() {
  var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _document;
  var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'scrollTop';
  var to = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var duration = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 100;
  var transition = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : DEFAULT_ANIMATION;
  var callback = arguments[5];

  var id = void 0;
  var start = direction === 'scrollTop' ? element.scrollTop : element.scrollLeft;
  var distance = to - start;
  var animationStartTime = +new Date();
  var isAnimating = true;
  var lastScrolledPosition = void 0;
  var transitionFunction = void 0;

  if (typeof transition === 'string' || transition === null) {
    transitionFunction = findAnimation(transition);
  } else if (typeof transition === 'function') {
    transitionFunction = defineAnimation(transition);
  } else {
    throw new Error(TRANSITION_NOT_VALID);
  }

  var animateScroll = function animateScroll() {
    var now = +new Date();
    var newScrollPosition = Math.floor(transitionFunction(now - animationStartTime, start, distance, duration));

    if (!lastScrolledPosition || to !== element[direction]) {
      element[direction] = newScrollPosition;
      lastScrolledPosition = newScrollPosition;
    } else {
      isAnimating = false;
      if (callback) {
        callback(ANIMATION_CANCEL);
        rAF.cancel(id);
      }
    }

    if (now > animationStartTime + duration) {
      element[direction] = to;
      isAnimating = false;
      if (callback) {
        callback(ANIMATION_END);
        rAF.cancel(id);
      }
    }

    if (isAnimating) {
      id = rAF.request(animateScroll);
    }
  };

  id = rAF.request(animateScroll);
};

// Publish public method in window
if (_window !== {}) {
  _window.scrollToWithAnimation = scrollToWithAnimation;
}

exports.default = scrollToWithAnimation;
exports.rAF = rAF;