import 'babel-polyfill';
import ready from 'document-ready';
import checkElement from './checkElement';
import userRegistration from './userRegistration';
import ageGate from './ageGate';
import cookieNotice from './cookieNotice';
import modal from './modal';
import burger from './burger';
import scrollTo from './scrollTo';

function init() {
    ageGate();

    checkElement('.cookie-notice')
        .then(() => {
            cookieNotice();
        })
        .catch((err) => {
            if (err) console.log(err);
        });    

    checkElement('.block-modal')
        .then(() => {
            modal();
        })
        .catch((err) => {
            if (err) console.log(err);
        });

    checkElement('.burger')
        .then(() => {
            burger();
        })
        .catch((err) => {
            if (err) console.log(err);
        });

    scrollTo();
}

ready(() => {
    init();
});