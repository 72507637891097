export default () => {
    // Methods
    const methods = {
        init() {
            if (!localStorage.getItem('cookie-notice-closed')) {
                document.querySelector('.cookie-notice').style.display = 'block';
            }
        },
        handleClose() {
            document.querySelector('.cookie-notice').style.display = 'none';
            localStorage.setItem('cookie-notice-closed', 1);
        }
    };
    methods.init();

    // Listeners
    document.querySelector('.cookie-notice .close')
        .addEventListener('click', methods.handleClose, false);
};
