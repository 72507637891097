import $ from 'jquery';
import request from 'superagent';

export default () => {
    const formElement = document.querySelector('form[name="user-registration"]');
    const postUrl = themeConfig.baseUrl + '/Common/Api/userRegistration.php';

    /**
     * Methods
     */
    let methods = {
        getData: () => (
            {
                name: formElement.querySelector('form input[name="name"]').value,
                email: formElement.querySelector('form input[name="email"]').value
            }
        ),
        send: () => {
            const data = methods.getData();
            request
                .post(postUrl)
                .type('form')
                .send({name: data.name})
                .send({email: data.email})
                .end((err, res) => {
                    if (err) throw err;
                    const response = JSON.parse(res.text);
                    methods.processResponse(response);
                });
        },
        processResponse: (response) => {
            if(!!response.errors) {
                methods.displayErrors(response);
            } else {
                methods.displaySuccess(response);
            }
        },
        displayErrors: (response) => {
            $('.error-messages').html('');
            $.each(response.errors, (i,item) => {
                $('.error-messages').append('<li>' + item + '</li>')
            });
        },
        displaySuccess: (response) => {
            window.location = themeConfig.registration_confirmation_url;
        }
    }

    /**
     * Events
     */
    formElement
    .addEventListener('submit', (e) => {
        e.preventDefault();
        methods.send();
        return false;
    }, false);
}